import {
  getInstance,
  endpoints,
  FarmersResponse,
  InviteUsersResponse
} from "lib/api";
import { UpdateFarmerRequestType } from "lib/api/requests";

const fetchFarmers = async () => {
  const farmersResponse: FarmersResponse = await getInstance.get(
    `${endpoints.farmers}/`
  );

  return farmersResponse;
};

const inviteFarmersToShop = async (
  contacts: string[]
): Promise<InviteUsersResponse> => {
  const response = await getInstance.post(endpoints.inviteFarmers, {
    contacts
  });

  return response;
};

const updateFarmer = async ({ farmerId, farmer }: UpdateFarmerRequestType) => {
  const { email, firstName, lastName, mobile } = farmer;
  const response = await getInstance.patch(
    `${endpoints.farmers}/${farmerId}/`,
    { first_name: firstName, last_name: lastName, email, mobile }
  );

  return response;
};

export { fetchFarmers, inviteFarmersToShop, updateFarmer };
