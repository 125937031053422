import React, { Fragment, lazy } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectSnackbar, triggerSnackBar } from "components/uiSlice";
import { handleSnackBarIcon } from "./utils";

const Transition = lazy(() =>
  import("@headlessui/react").then(module => {
    return { default: module.Transition };
  })
);

const SnackBar = () => {
  const snackBarState = useAppSelector(selectSnackbar);
  const dispatch = useAppDispatch();
  const isShown = !!snackBarState.message;
  const hideSnackBar = () => {
    dispatch(triggerSnackBar({ message: "", type: null }));
  };

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-10 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={isShown}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterEnter={() => setTimeout(hideSnackBar, 3000)}
        >
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {handleSnackBarIcon(snackBarState.type)}
                </div>
                <div className="ml-3 w-0 flex-1">
                  <p className="text-sm text-gray-500">
                    {snackBarState.message}
                  </p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={hideSnackBar}
                  >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default SnackBar;
