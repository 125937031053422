export const routes = {
  login: "login",
  registerShop: "register/shop",
  registerAdmin: "register/admin",
  phoneVerification: "register/phone-verification",
  passwordGeneration: "register/password-generation",
  registerAgronomist: "register/agronomist",
  forgotPassword: "forgot-password",
  farmers: "farmers",
  account: "account",
  shop: "shop/:shopId",
  chat: "chat",
  farm: "farm"
};

export const unprotectedRoutes = [
  routes.login,
  routes.registerShop,
  routes.registerAdmin,
  routes.registerAgronomist,
  routes.phoneVerification,
  routes.passwordGeneration,
  routes.forgotPassword
];
