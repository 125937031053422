import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Agronomist, Shop } from "types/models";
import { fetchAgronomists, inviteAgronomistsToShop } from "./shopAPI";

const initialState: Shop = {
  address: "",
  email: "",
  name: "",
  phone: "",
  tin: "",
  agronomists: []
};

export const inviteAgronomists = createAsyncThunk(
  "shop/inviteAgronomists",
  async (data: { contacts: string[] }, { rejectWithValue }) => {
    try {
      const response = await inviteAgronomistsToShop(data.contacts);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAgronomists = createAsyncThunk(
  "shop/getAgronomists",
  async () => {
    try {
      const response = await fetchAgronomists();
      const formattedAgronomists: Agronomist[] = response.data.map(
        agronomist => ({
          ...agronomist,
          firstName: agronomist.first_name,
          lastName: agronomist.last_name,
          fullName: `${agronomist.first_name} ${agronomist.last_name}`
        })
      );
      return formattedAgronomists;
    } catch (error) {
      return error;
    }
  }
);

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    updateShop: (state, action: PayloadAction<Shop>) => {
      state.address = action.payload.address;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.phone = action.payload.phone;
      state.tin = action.payload.tin;
    }
  },
  extraReducers: builder => {
    builder.addCase(getAgronomists.fulfilled, (state, action) => {
      state.agronomists = action.payload;
    });
  }
});

export const { updateShop } = shopSlice.actions;

export const selectShop = (state: RootState) => state.shop;
export const selectAgronomists = (state: RootState) => state.shop.agronomists;

export default shopSlice.reducer;
