/* eslint-disable react/no-array-index-key */
import { Button, FormWrapper, Input } from "components";
import React, { useState } from "react";
import { useAppDispatch } from "app/hooks";
import { inviteAgronomists } from "features/shop/shopSlice";
import { inviteFarmers } from "features/farmers/farmersSlice";
import { validateEmail, validatePhoneNumber } from "lib/utils/validation";
import { ButtonVariant } from "components/button/types";
import { triggerSnackBar } from "components/uiSlice";
import { SnackBarConstType } from "types/models/SnackBar";

const InviteUsersModal = ({
  toggleModal,
  onSuccess,
  inviteUsersSection
}: {
  toggleModal: () => void;
  onSuccess: () => void;
  inviteUsersSection?: "agronomists" | "farmers";
}) => {
  const dispatch = useAppDispatch();
  const [userList, setUserList] = useState([""]);

  const updateListItem = (index: number, newValue: string) => {
    const newArray = [...userList];
    newArray[index] = newValue;
    setUserList(newArray);
  };

  const addNewItem = () => {
    setUserList([...userList, ""]);
  };

  const handleClick = () => {
    const inviteUsers =
      inviteUsersSection === "agronomists" ? inviteAgronomists : inviteFarmers;
    const filteredList = userList.filter(item =>
      inviteUsersSection === "agronomists"
        ? validateEmail(item)
        : validatePhoneNumber(item)
    );
    dispatch(inviteUsers({ contacts: filteredList }))
      .unwrap()
      .then(() => {
        onSuccess();
        dispatch(
          triggerSnackBar({
            message: "Επιτυχής προσθήκη!",
            type: SnackBarConstType.SUCCESS
          })
        );
        toggleModal();
      })
      .catch(() => {
        dispatch(
          triggerSnackBar({
            message: "Παρουσιάστηκε σφάλμα",
            type: SnackBarConstType.ERROR
          })
        );
      });
  };

  const handleButtonUsersNumberLabel = () => {
    const filteredList = userList.filter(item => validateEmail(item));
    return filteredList.length ? `(${filteredList.length})` : "";
  };

  const renderForm = () => {
    return (
      <div>
        <div className="flex flex-col gap-4">
          {userList.map((str, index) => (
            <div key={index}>
              <Input
                name={`${str}-invite-agronomist`}
                placeholder="Email ή κινητό"
                value={str}
                onChange={e => updateListItem(index, e.target.value)}
              />
            </div>
          ))}
        </div>
        <Button
          label="+Προσθήκη"
          variant={ButtonVariant.DASHED}
          className="mt-4 w-full"
          onClick={addNewItem}
        />
        <Button
          label={`Πρόσκληση τώρα${handleButtonUsersNumberLabel()}`}
          className="mt-8 w-full"
          onClick={handleClick}
        />
      </div>
    );
  };

  return (
    <FormWrapper title="Πρόσκληση μελών" withStep={false} form={renderForm()} />
  );
};

export default InviteUsersModal;
