import React from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { selectToken } from "features/user/userSlice";
import { getLocalStorageAccessToken } from "lib/utils/storage";
import SignedInLayout from "./components/SignedInLayout";

const Layout = () => {
  const stateToken = useAppSelector(selectToken);
  const storageToken = getLocalStorageAccessToken();
  const isSignedIn = !!(storageToken || stateToken);

  return (
    <div id="layout" className="h-screen w-full">
      {isSignedIn ? (
        <div>
          <SignedInLayout />
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default Layout;
