import React from "react";
import { FormWrapper } from "components";

const ShopData = ({ shopForm }: { shopForm: () => JSX.Element }) => {
  return (
    <FormWrapper
      title="Στοιχεία επιχείρησης"
      withStep={false}
      form={shopForm()}
    />
  );
};

export default ShopData;
