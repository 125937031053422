import { FormWrapper, Input, Button } from "components";
import React from "react";
import { useForm } from "react-hook-form";
import { useAppSelector, useAppDispatch } from "app/hooks";
import {
  getFarmers,
  selectFarmer,
  updateFarmer
} from "features/farmers/farmersSlice";
import { FarmerDetailsRequestType } from "lib/api/requests";
import { triggerSnackBar } from "components/uiSlice";
import { SnackBarConstType } from "types/models/SnackBar";
import { EDIT_FARMER_FIELDS } from "./config";

type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  mobile: string;
};

const EditFarmer = ({ toggleModal }: { toggleModal: () => void }) => {
  const selectedFarmer = useAppSelector(selectFarmer);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      email: selectedFarmer.email,
      firstName: selectedFarmer.firstName,
      lastName: selectedFarmer.lastName,
      mobile: selectedFarmer.mobile
    }
  });

  const onSubmit = async (data: FormData) => {
    const { firstName, lastName, email, mobile } = data;
    const payload: FarmerDetailsRequestType = {
      firstName,
      lastName,
      email,
      mobile
    };
    dispatch(updateFarmer({ farmer: payload, farmerId: selectedFarmer.id }))
      .unwrap()
      .then(() => {
        dispatch(getFarmers());
        toggleModal();
      })
      .catch(() => {
        dispatch(
          triggerSnackBar({
            message: "Παρουσιάστηκε σφάλμα",
            type: SnackBarConstType.ERROR
          })
        );
      });
  };

  const renderForm = () => {
    return (
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register("email", { ...EDIT_FARMER_FIELDS.email })}
          name="email"
          placeholder="Email"
          errorMessage={errors?.email?.message}
        />
        <Input
          {...register("mobile", { ...EDIT_FARMER_FIELDS.mobile })}
          name="mobile"
          placeholder="Τηλέφωνο"
          required
          disabled
          maxLength={10}
          errorMessage={errors?.mobile?.message}
        />
        <div className="flex gap-4">
          <Input
            {...register("firstName", { ...EDIT_FARMER_FIELDS.firstName })}
            name="firstName"
            placeholder="Όνομα"
            required
            className="w-1/2"
          />
          <Input
            {...register("lastName", { ...EDIT_FARMER_FIELDS.lastName })}
            name="lastName"
            placeholder="Επώνυμο"
            required
            className="w-1/2"
          />
        </div>
        <div className="flex justify-end">
          <Button type="submit" label="Αποθήκευση" />
        </div>
      </form>
    );
  };

  return (
    <FormWrapper title="Στοιχεία χρήστη" form={renderForm()} withStep={false} />
  );
};

export default EditFarmer;
