import React from "react";
import { ButtonProps } from "./types";
import { handleVariantStyles } from "./utils";

const Button = ({
  label,
  variant = "primary",
  onClick,
  disabled = false,
  type = "button",
  className = "",
  withIcon = false,
  Icon
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`flex items-center justify-center rounded-[10px] p-4 text-center text-lg font-semibold ${handleVariantStyles(
        variant
      )} ${className} ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
    >
      {label}
      {withIcon ? <div>{Icon}</div> : null}
    </button>
  );
};

export default Button;
