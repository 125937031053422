export const endpoints = {
  login: "/auth/login/",
  logout: "/auth/logout/",
  refreshToken: "/auth/refresh-token/",
  authVerify: "/auth/verify/",
  validateShop: "/public/register/validate-shop/",
  validateOwner: "/public/register/validate-owner/",
  validateFarmer: "/public/register/validate-farmer/",
  register: "/public/register/",
  createUserPassword: "/me/set-password/",
  userInformation: "/me/profile/",
  forgotPassword: "/me/forgot-password/",
  shops: "/shops",
  farmers: "/farmers",
  agronomists: "/agronomists/",
  magicLink: "/magiclink/resolve",
  inviteAgronomists: "/agronomists/invite/",
  inviteFarmers: "/farmers/invite/"
};
