import React, { useState } from "react";
import { Button, Input } from "components";
import { useNavigate } from "react-router-dom";
import { Lock } from "assets/icons";
import { RegisterLayout } from "features/register/components";
import { getInstance, endpoints, SubmitRegistrationResponse } from "lib/api";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectFields } from "features/register/registerSlice";
import { updateToken } from "features/user/userSlice";
import {
  setLocalStorageRefreshToken,
  setLocalStorageToken
} from "lib/utils/storage";
import { triggerSnackBar } from "components/uiSlice";
import { SnackBarConstType } from "types/models/SnackBar";

const CreatePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectFields);
  const navigate = useNavigate();

  const handleSubmit = () => {
    const payload = { password, repeated: confirmPassword };
    const bearerToken = `Bearer ${fields.phoneVerificationToken}`;

    getInstance
      .post(endpoints.createUserPassword, payload, {
        headers: {
          Authorization: bearerToken
        }
      })
      .then((response: SubmitRegistrationResponse) => {
        setLocalStorageToken(response.data.access);
        setLocalStorageRefreshToken(response.data.refresh);
        dispatch(
          updateToken({
            access: `Bearer ${response.data.access}`,
            refresh: `Bearer ${response.data.refresh}`
          })
        );
        navigate("/");
      })
      .catch(error => {
        Object.values(error.response.data).map((errorMessages: string[]) =>
          errorMessages.map(message =>
            dispatch(
              triggerSnackBar({ message, type: SnackBarConstType.WARNING })
            )
          )
        );
      });
  };

  return (
    <RegisterLayout>
      <div className="flex w-full max-w-md flex-col justify-center rounded-10 border border-solid border-opacity-100 bg-white px-12 pb-14 pt-12 shadow-100">
        <div className="mb-9 flex flex-col items-center justify-center">
          <Lock className="text-green-50" />
          <span className="mt-5 text-2xl font-extrabold">
            Ενεργοποίηση λογαριασμού
          </span>
        </div>
        <div className="mb-12 flex flex-col items-center gap-4">
          <Input
            name="password"
            onChange={event => setPassword(event.target.value)}
            type="password"
            value={password}
            placeholder="Νέος κωδικός"
            className="w-full max-w-xs"
            required
          />
          <Input
            name="confirmPassword"
            onChange={event => setConfirmPassword(event.target.value)}
            type="password"
            value={confirmPassword}
            placeholder="Επιβεβαίωση κωδικού"
            className="w-full max-w-xs"
            required
          />
        </div>
        <Button
          label="Είσοδος στην εφαρμογή"
          onClick={handleSubmit}
          disabled={password ? password !== confirmPassword : true}
          className="font-bold"
        />
      </div>
    </RegisterLayout>
  );
};

export default CreatePassword;
