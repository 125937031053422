export const emailRegxPattern =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const validateTaxNumber = (taxNumber: string) => {
  if (taxNumber.length !== 9) {
    return false;
  }

  if (!/^\d+$/.test(taxNumber)) {
    // Only numbers
    return false;
  }

  if (taxNumber === "0".repeat(9)) {
    return false;
  }

  const sum = taxNumber
    .substring(0, 8)
    .split("")
    // eslint-disable-next-line no-bitwise
    .reduce((s, v, i) => s + (parseInt(v, 10) << (8 - i)), 0);

  const calc = sum % 11;
  const d9 = parseInt(taxNumber[8], 10);
  const valid = calc % 10 === d9;

  return valid;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.startsWith("2") || phoneNumber.startsWith("69")) {
    return true;
  }

  return false;
};

export const validateEmail = (email: string) => {
  if (email?.match(emailRegxPattern)) {
    return true;
  }

  return false;
};
