import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { User } from "types/models";
import {
  removeLocalStorageTokens,
  setLocalStorageRefreshToken,
  setLocalStorageToken
} from "lib/utils/storage";
import { logInUser, fetchUserInformation, logOutUser } from "./userAPI";

export interface UserState {
  user: User;
  token: string;
  refreshToken: string;
}

const initialState: UserState = {
  user: null,
  token: null,
  refreshToken: null
};

export const getUserInformation = createAsyncThunk(
  "user/getUserInformation",
  async () => {
    try {
      const response = await fetchUserInformation();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const signInUser = createAsyncThunk(
  "user/signInUser",
  async (data: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await logInUser(data.email, data.password);
      setLocalStorageToken(response.access);
      setLocalStorageRefreshToken(response.refresh);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signOutUser = createAsyncThunk(
  "user/signOutUser",
  async (data: { refreshToken: string; accessToken: string }) => {
    try {
      const response = await logOutUser(data.refreshToken);
      removeLocalStorageTokens();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateToken: (
      state,
      action: PayloadAction<{ access: string; refresh: string }>
    ) => {
      state.token = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = {
        ...state.user,
        ...action.payload
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(signOutUser.fulfilled, state => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
    });
    builder.addCase(
      getUserInformation.fulfilled,
      (state, action: PayloadAction<User>) => {
        state.user = {
          ...action.payload
        };
      }
    );
    builder.addCase(
      signInUser.fulfilled,
      (
        state,
        action: PayloadAction<{
          access: string;
          refresh: string;
        }>
      ) => {
        const { access, refresh } = action.payload;
        state.token = access;
        state.refreshToken = refresh;
      }
    );
  }
});

export const { updateToken, updateUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;
export const selectToken = (state: RootState) => state.user.token;
export const selectRefreshToken = (state: RootState) => state.user.refreshToken;

export default userSlice.reducer;
