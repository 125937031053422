import { ObjectValues } from "./Common";

export const REGISTER_SCOPE = {
  SET_PASSWORD: "set-password",
  RESET_PASSWORD: "reset-password",
  REGISTER_AGRONOMIST: "register",
  REGISTER_FARMER: "register-farmer"
} as const;

export type RegisterScope = ObjectValues<typeof REGISTER_SCOPE>;
