import { Button, FormWrapper, NameInitials } from "components";
import { ButtonVariant } from "components/button/types";
import React from "react";
import { Agronomist } from "types/models";

const MemberLabel = ({ isAdmin }: { isAdmin?: boolean }) => {
  return (
    <div
      className={`${
        isAdmin ? "bg-green-50" : "bg-blue-50"
      } flex h-5 items-center justify-center rounded-3xl px-2`}
    >
      <span className="text-[10px] font-semibold text-white">
        {isAdmin ? "Admin" : "Member"}
      </span>
    </div>
  );
};

const TeamMember = ({ member }: { member: Agronomist }) => {
  return (
    <div className="flex items-center justify-between rounded-10 bg-grey-300 px-4 py-3">
      <div className="flex items-center gap-5">
        <NameInitials firstName={member.firstName} lastName={member.lastName} />
        <div className="font-semibold">{member.fullName}</div>
      </div>
      <MemberLabel isAdmin />
    </div>
  );
};

const TeamData = ({
  members,
  addMember
}: {
  members: Agronomist[];
  addMember: () => void;
}) => {
  const form = () => {
    return (
      <div className="h-full">
        <div className="flex h-full max-h-60 flex-col gap-4 overflow-auto">
          {members.map(member => (
            <TeamMember key={member.id} member={member} />
          ))}
        </div>
        <div className="sticky bottom-0 mt-4">
          <Button
            label="+Προσθήκη νέου μέλους"
            variant={ButtonVariant.DASHED}
            className="w-full"
            onClick={addMember}
          />
        </div>
      </div>
    );
  };

  return (
    <FormWrapper
      title="Η ομάδα μου"
      form={form()}
      withStep={false}
      className="max-h-[456px]"
    />
  );
};

export default TeamData;
