import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { UserIcon, SearchIcon } from "assets/icons";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  signOutUser,
  selectRefreshToken,
  selectToken,
  selectUser
} from "features/user/userSlice";
import { routes } from "routes/utils";
import { ShopDetailsResponse, endpoints, getInstance } from "lib/api";
import { updateShop } from "features/shop/shopSlice";
import { Menu } from "components";

const Header = ({ pageTitle }: { pageTitle: string }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const refreshToken = useAppSelector(selectRefreshToken);
  const accessToken = useAppSelector(selectToken);

  const handleNavigate = async () => {
    const shopDetails: ShopDetailsResponse = await getInstance.get(
      `${endpoints.shops}/${user.shopId}/`
    );

    if (shopDetails.data) {
      dispatch(updateShop(shopDetails.data));
      const path = generatePath(`/${routes.shop}`, { shopId: user.shopId });
      navigate(path);
    }
  };

  const userNavigationItems = [
    {
      name: "Ο λογαριασμός μου",
      onClick: () => navigate(`/${routes.account}`)
    },
    user.isAdmin && { name: "Η επιχείρηση μου", onClick: handleNavigate },
    {
      name: "Αποσύνδεση",
      onClick: () => dispatch(signOutUser({ refreshToken, accessToken }))
    }
  ].filter(item => item);

  return (
    <div className="flex justify-between">
      <span className="text-5xl font-extrabold">{pageTitle}</span>
      <div className="flex items-center gap-x-12">
        <button type="button" className="text-black">
          <SearchIcon className="h-8 w-8" aria-hidden="true" />
        </button>

        <Menu
          menuItems={userNavigationItems}
          menuIcon={<UserIcon className="h-8 w-8" aria-hidden="true" />}
        />
      </div>
    </div>
  );
};

export default Header;
