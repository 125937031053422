import React from "react";
import { useNavigate } from "react-router-dom";
import { EmailCheck } from "assets/icons";
import { routes } from "routes/utils";

const ForgotPasswordSuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex w-full max-w-xl flex-col rounded-10 border border-solid border-opacity-100 bg-white p-9 shadow-100">
        <EmailCheck />
        <div className="mt-8 flex flex-col">
          <span className="mb-3 text-3xl font-extrabold">Έγινε αποστολή</span>
          <div className="text-sm text-opacity-600">
            <span>
              Εντός 2 λεπτών θα λάβετε στο κινητό σας έναν σύνδεσμο επαναφοράς
              της μορφής (https://agros.ai/r/jsah731hsd)
            </span>
            <div className="my-5">
              <span className="underline">Ακολουθήστε τη διαδικασία:</span>
            </div>
            <div className="flex flex-col">
              <span>1. Κάντε κλικ στο σύνδεσμο</span>
              <span>2. Στη σελίδα που θα σας ανοίξει εισάγετε νέο κωδικό</span>
              <span>3. Πατήστε υποβολή</span>
              <span>4. Συνδεθείτε με τον νέο σας κωδικό</span>
            </div>
          </div>
          <div className="mt-6 w-fit">
            <span className="cursor-pointer text-sm font-semibold underline">
              Δεν έλαβα το μήνυμα
            </span>
          </div>
          <div
            className="mt-16 flex justify-end"
            onClick={() => navigate(`/${routes.login}`)}
            role="none"
          >
            <span className="cursor-pointer text-sm font-semibold text-opacity-600">
              Επιστροφή στην αρχική
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccessMessage;
