import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Phone, Mail, Chat, Kebap, Check, Rocket, Plus } from "assets/icons";
import {
  Button,
  InviteUsersModal,
  Menu,
  Modal,
  NameInitials
} from "components";
import { endpoints, getInstance } from "lib/api";
import { triggerSnackBar } from "components/uiSlice";
import { selectUser } from "features/user/userSlice";
import { UserPersona } from "types/models";
import { useNavigate } from "react-router-dom";
import {
  getFarmers,
  selectFarmers,
  updateSelectedFarmer
} from "./farmersSlice";
import { ModalMode, ModalType } from "./types";
import { EditFarmer } from "./components";

const Farmers = () => {
  const [isOpen, setOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(null);
  const dispatch = useAppDispatch();
  const farmers = useAppSelector(selectFarmers);
  const selectedUser = useAppSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFarmers());
  }, [dispatch]);

  if (selectedUser.persona === UserPersona.FARMER) {
    navigate("/");
  }

  return farmers.length ? (
    <section>
      <div className="mb-4 flex justify-between font-semibold text-grey-200">
        <span>{farmers.length} επαφές</span>
        <div
          onClick={() => {
            setModalType(ModalMode.INVITE_FARMER);
            setOpen(true);
          }}
          role="none"
        >
          <Plus className="cursor-pointer" />
        </div>
      </div>
      <div className="flex flex-wrap gap-6">
        {farmers.map(farmer => (
          <div
            key={farmer.mobile}
            className="min-w-[220px] max-w-[240px] rounded-[10px] border border-solid border-opacity-10 bg-white p-4 shadow-100"
          >
            <div className="mb-3 flex justify-between">
              {farmer.avatar ? (
                <img
                  src={farmer.avatar}
                  alt="farmer-avatar"
                  className="h-12 w-12 rounded-lg object-cover object-center"
                />
              ) : (
                <NameInitials
                  firstName={farmer.firstName}
                  lastName={farmer.lastName}
                  className="!h-12 !w-12 rounded-lg"
                />
              )}
              <Menu
                menuItems={[
                  {
                    name: "Επεξεργασία",
                    onClick: () => {
                      setModalType(ModalMode.EDIT_FARMER);
                      dispatch(updateSelectedFarmer(farmer));
                      setOpen(true);
                    }
                  },
                  {
                    name: "Πρόσκληση",
                    onClick: () =>
                      getInstance
                        .post(
                          `${endpoints.farmers}/${farmer.id}/send-invitation/`
                        )
                        .then(() =>
                          dispatch(
                            triggerSnackBar({
                              message: "Η πρόσκληση στάλθηκε",
                              type: "success"
                            })
                          )
                        )
                        .catch(() =>
                          dispatch(
                            triggerSnackBar({
                              message: "Προσπαθήστε ξανά",
                              type: "error"
                            })
                          )
                        )
                  }
                ]}
                menuIcon={<Kebap className="cursor-pointer" />}
              />
            </div>
            <div className="mb-2 flex flex-col text-xl font-semibold tracking-tighter">
              <span>{farmer.firstName}</span>
              <span>{farmer.lastName}</span>
            </div>
            {farmer.totalArea && (
              <div className="mt-2 flex max-w-fit items-center gap-1 rounded-[30px] bg-opacity-500 px-2 py-1">
                <Check className="text-green-50" />
                <span className="text-xs font-semibold">
                  {farmer.totalArea} τμ.
                </span>
              </div>
            )}
            {!!farmer.crops?.length && (
              <div className="mt-2 flex gap-1 overflow-x-auto">
                {farmer.crops?.map(product => (
                  <div
                    key={product}
                    className="rounded-[30px] border border-opacity-500 px-2 py-[6px] text-[11px] font-semibold text-black"
                  >
                    {product}
                  </div>
                ))}
              </div>
            )}

            <div className="mt-3 flex items-center">
              <Phone />
              <span className="ml-2 text-sm font-medium">{farmer.mobile}</span>
            </div>
            <div className="mt-3 flex items-center">
              <Mail />
              <span className="ml-2 truncate text-sm font-medium">
                {farmer.email}
              </span>
            </div>
            <div className="mt-4 flex justify-center">
              <div className="cursor-pointer rounded border px-6 py-2">
                <Chat />
              </div>
              <div className="ml-2 cursor-pointer rounded border px-6 py-2">
                <Rocket />
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal open={isOpen} setOpen={setOpen}>
        {modalType === ModalMode.INVITE_FARMER ? (
          <InviteUsersModal
            toggleModal={() => setOpen(!isOpen)}
            onSuccess={() => dispatch(getFarmers())}
            inviteUsersSection="farmers"
          />
        ) : (
          <EditFarmer toggleModal={() => setOpen(!isOpen)} />
        )}
      </Modal>
    </section>
  ) : (
    <div className="my-40 flex flex-col items-center justify-center">
      <span className="mb-8 font-semibold text-gray-500">
        Δεν υπάρχουν μέλη.
      </span>
      <Button
        label="Προσθήκη"
        onClick={() => {
          setModalType(ModalMode.INVITE_FARMER);
          setOpen(true);
        }}
        withIcon
        Icon={<Plus width="24px" height="24px" className="ml-3" />}
      />
      <Modal open={isOpen} setOpen={setOpen}>
        {modalType === ModalMode.INVITE_FARMER ? (
          <InviteUsersModal
            toggleModal={() => setOpen(!isOpen)}
            onSuccess={() => dispatch(getFarmers())}
            inviteUsersSection="farmers"
          />
        ) : (
          <EditFarmer toggleModal={() => setOpen(!isOpen)} />
        )}
      </Modal>
    </div>
  );
};

export default Farmers;
