import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectFields, updateState } from "features/register/registerSlice";
import { Button } from "components";
import { routes } from "routes/utils";
import { RegisterLayout } from "features/register/components";
import { PhoneVerificationResponse, endpoints, getInstance } from "lib/api";
import { triggerSnackBar } from "components/uiSlice";
import { SnackBarConstType } from "types/models/SnackBar";
import { OtpInput } from "./components";

const OneTimePassword = () => {
  const [otpValue, setOtpValue] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectFields);

  const handleSubmit = () => {
    const payload = { otp: otpValue };
    const headerToken = fields.ownerRegistrationToken;

    getInstance
      .post(endpoints.register, payload, {
        headers: {
          "X-AGROS-REGISTRATION-ID": headerToken
        }
      })
      .then((response: PhoneVerificationResponse) => {
        dispatch(
          updateState({
            key: "phoneVerificationToken",
            value: response.data.token
          })
        );
        navigate(`/${routes.passwordGeneration}`);
      })
      .catch(error => {
        Object.values(error.response.data).map((errorMessages: string[]) =>
          errorMessages.map(message =>
            dispatch(
              triggerSnackBar({ message, type: SnackBarConstType.WARNING })
            )
          )
        );
      });
  };

  return (
    <RegisterLayout>
      <div className="flex w-full max-w-xl flex-col justify-center gap-4 rounded-10 border border-solid border-opacity-100 bg-white px-9 pb-9 pt-8 shadow-100">
        <div className="mb-11 flex flex-col">
          <span className="mb-2 text-xs opacity-60">Βήμα 3 από 3</span>
          <span className="text-3xl font-extrabold">Επιβεβαίωση τηλεφώνου</span>
        </div>
        <div className="rounded-10 bg-grey-300 pb-9 pt-11">
          <div className="mb-6 flex w-full justify-center text-center">
            <div className="max-w-[250px] font-bold text-grey-400">
              Στείλαμε έναν 6ψήφιο κωδικό στο 69********
            </div>
          </div>
          <div className="w-full text-center">
            <OtpInput
              value={otpValue}
              valueLength={6}
              onChange={value => setOtpValue(value)}
            />
          </div>
          <div className="mt-10 text-center">
            <span className="cursor-pointer text-sm text-grey-400 underline opacity-30">
              Λάθος κινητό;
            </span>
          </div>
        </div>
        <div className="mt-11 flex items-center justify-end gap-6">
          <Button label="Συνέχεια" className="w-2/4" onClick={handleSubmit} />
        </div>
      </div>
    </RegisterLayout>
  );
};

export default OneTimePassword;
