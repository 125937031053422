import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Input, FormWrapper } from "components";
import { REGISTER_FORM_FIELDS } from "features/register/config";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectFields, updateState } from "features/register/registerSlice";
import { RegisterFormData } from "features/register/types";
import { routes } from "routes/utils";
import { getInstance, endpoints, OwnerRegistrationResponse } from "lib/api";
import { triggerSnackBar } from "components/uiSlice";
import { SnackBarConstType } from "types/models/SnackBar";
import RegisterLayout from "../RegisterLayout";

const AdminDetails = () => {
  const fields = useAppSelector(selectFields);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<RegisterFormData>({
    defaultValues: fields,
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formFields = watch();

  const onSubmitOfRegistration = () => {
    Object.keys(formFields).map((key: keyof RegisterFormData) =>
      dispatch(updateState({ key, value: formFields[key] }))
    );

    const headerToken = fields.shopRegistrationToken;

    const payload = {
      first_name: formFields.firstName,
      last_name: formFields.lastName,
      email: formFields.email,
      mobile: formFields.mobilePhone
    };

    getInstance
      .post(endpoints.validateOwner, payload, {
        headers: {
          "X-AGROS-REGISTRATION-ID": headerToken
        }
      })
      .then((response: OwnerRegistrationResponse) => {
        dispatch(
          updateState({
            key: "ownerRegistrationToken",
            value: response.data.token
          })
        );
        navigate(`/${routes.phoneVerification}`);
      })
      .catch(error => {
        Object.values(error.response.data).map((errorMessages: string[]) =>
          errorMessages.map(message =>
            dispatch(
              triggerSnackBar({ message, type: SnackBarConstType.WARNING })
            )
          )
        );
      });
  };

  const renderSection = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmitOfRegistration)}
        className="flex flex-col gap-4"
      >
        <Input
          {...register("email", { ...REGISTER_FORM_FIELDS.email })}
          name="email"
          placeholder="Email"
          required={REGISTER_FORM_FIELDS.email.required}
          errorMessage={errors?.email?.message}
        />
        <Input
          {...register("mobilePhone", { ...REGISTER_FORM_FIELDS.mobilePhone })}
          name="mobilePhone"
          placeholder="Κινητό τηλέφωνο"
          required={REGISTER_FORM_FIELDS.mobilePhone.required}
          maxLength={10}
          errorMessage={errors?.mobilePhone?.message}
        />
        <div className="flex gap-6">
          <Input
            {...register("firstName", { ...REGISTER_FORM_FIELDS.firstName })}
            name="firstName"
            placeholder="Όνομα"
            className="w-2/4"
            required={REGISTER_FORM_FIELDS.firstName.required}
          />

          <Input
            {...register("lastName", { ...REGISTER_FORM_FIELDS.lastName })}
            name="lastName"
            placeholder="Επώνυμο"
            className="w-2/4"
            required={REGISTER_FORM_FIELDS.lastName.required}
          />
        </div>
        <div className="mt-11 flex items-center justify-between gap-6">
          <div className="w-2/4">
            <button
              type="button"
              className="cursor-pointer text-left text-sm font-semibold opacity-60"
              onClick={() => navigate(`/${routes.registerShop}`)}
            >
              Πίσω
            </button>
          </div>
          <Button label="Συνέχεια" className="w-2/4" type="submit" />
        </div>
      </form>
    );
  };

  return (
    <RegisterLayout>
      <FormWrapper title="Στοιχεία χρήστη" step={2} form={renderSection()} />
    </RegisterLayout>
  );
};

export default AdminDetails;
