import React, { Fragment, lazy } from "react";

const Transition = lazy(() =>
  import("@headlessui/react").then(module => {
    return { default: module.Transition };
  })
);

const MenuButton = lazy(() =>
  import("@headlessui/react").then(module => {
    const {
      Menu: { Button }
    } = module;
    return { default: Button };
  })
);

const MenuItem = lazy(() =>
  import("@headlessui/react").then(module => {
    const {
      Menu: { Item }
    } = module;
    return { default: Item };
  })
);
const MenuItems = lazy(() =>
  import("@headlessui/react").then(module => {
    const {
      Menu: { Items }
    } = module;
    return { default: Items };
  })
);
const HeadlessUiMenu = lazy(() =>
  import("@headlessui/react").then(module => {
    return { default: module.Menu };
  })
);

const Menu = ({
  menuIcon,
  menuItems
}: {
  menuIcon: JSX.Element;
  menuItems: { name: string; onClick: () => void }[];
}) => {
  return (
    <HeadlessUiMenu as="div" className="relative">
      <MenuButton className="flex items-center text-black">
        {/* <UserIcon className="h-8 w-8" aria-hidden="true" /> */}
        {menuIcon}
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2.5 w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {menuItems.map(item => (
            <MenuItem key={item.name}>
              {(option: { active: boolean }) => (
                <button
                  type="button"
                  onClick={item.onClick}
                  className={`${
                    option.active ? "bg-gray-50" : ""
                  } w-full py-1 text-sm leading-6 text-gray-900`}
                >
                  {item.name}
                </button>
              )}
            </MenuItem>
          ))}
        </MenuItems>
      </Transition>
    </HeadlessUiMenu>
  );
};

export default Menu;
