import {
  getInstance,
  endpoints,
  InviteUsersResponse,
  AgronomistsResponse
} from "lib/api";

const inviteAgronomistsToShop = async (
  contacts: string[]
): Promise<InviteUsersResponse> => {
  const response = await getInstance.post(endpoints.inviteAgronomists, {
    contacts
  });

  return response;
};

const fetchAgronomists = async () => {
  const response: AgronomistsResponse = await getInstance.get(
    endpoints.agronomists
  );

  return response;
};

export { inviteAgronomistsToShop, fetchAgronomists };
