import { routes } from "routes/utils";

export default function getTitleFromLocation(path: string) {
  let title = "Αρχική";
  if (path.includes(routes.farmers)) {
    title = "Πελάτες";
  } else if (path.includes(routes.account)) {
    title = "Ο λογαριασμός μου";
  } else if (path.includes("shop")) {
    title = "Η επιχείρηση μου";
  } else if (path.includes(routes.chat)) {
    title = "Μηνύματα";
  } else if (path.includes(routes.farm)) {
    title = "Αγροτεμάχια";
  }
  return title;
}
