export const LOGIN_FORM_FIELDS = {
  email: {
    required: true,
    pattern: {
      value: /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|69\d{8})$/,
      message: "Παρακαλώ εισάγετε έγκυρο email ή κινητό"
    }
  },
  password: {
    required: true
  }
};
