import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "app/hooks";
import {
  Button,
  Input,
  InviteUsersModal,
  Modal,
  UploadInput
} from "components";
import { Shop } from "types/models";
import RandomFarmer from "assets/images/random-farmer.jpg";
import { ShopDetailsResponse, endpoints, getInstance } from "lib/api";
import { selectUser } from "features/user/userSlice";
import { selectShop, updateShop, getAgronomists } from "./shopSlice";
import { SHOP_FIELDS } from "./config";
import { ShopData, TeamData } from "./components";

const Shop = () => {
  const [imagePreview, setPreview] = useState<string>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const shop = useAppSelector(selectShop);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shopId } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<Shop>({
    defaultValues: shop,
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  });

  const onSubmit = (fields: Shop) => {
    getInstance
      .patch(`${endpoints.shops}/${shopId}/`, fields)
      .then((response: ShopDetailsResponse) => {
        dispatch(updateShop(response.data));
      });
  };

  const onSelectOfFile = (fileList: FileList) => {
    const imageUrl = URL.createObjectURL(fileList[0]);
    setPreview(imageUrl);
  };

  const shopForm = () => {
    return (
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <UploadInput
          onSelectOfFile={onSelectOfFile}
          imagePreview={imagePreview || RandomFarmer}
          className="w-1/2"
        />
        <Input
          {...register("tin", { ...SHOP_FIELDS.tin })}
          name="tin"
          placeholder="Α.Φ.Μ."
          required
          maxLength={9}
          errorMessage={errors?.tin?.message}
        />
        <Input
          {...register("name", { ...SHOP_FIELDS.name })}
          name="name"
          placeholder="Όνομα"
          required
        />
        <Input
          {...register("address", { ...SHOP_FIELDS.address })}
          name="address"
          placeholder="Διεύθυνση"
          required
        />
        <Input
          {...register("phone", { ...SHOP_FIELDS.phone })}
          name="phone"
          placeholder="Τηλέφωνο"
          required
          maxLength={10}
          errorMessage={errors?.phone?.message}
        />
        <Input
          {...register("email", { ...SHOP_FIELDS.email })}
          name="email"
          placeholder="Email"
          required
          errorMessage={errors?.email?.message}
        />
        <div className="flex justify-end">
          <Button type="submit" label="Αποθήκευση" />
        </div>
      </form>
    );
  };

  useEffect(() => {
    if (!shop.email) {
      getInstance
        .get(`${endpoints.shops}/${shopId}/`)
        .then((response: ShopDetailsResponse) => {
          const { address, email, name, phone, tin } = response.data;
          dispatch(updateShop(response.data));
          setValue("address", address);
          setValue("email", email);
          setValue("name", name);
          setValue("phone", phone);
          setValue("tin", tin);
        });
    }
    dispatch(getAgronomists());
  }, [dispatch, shopId, setValue, shop.email]);

  if (!user.isAdmin) {
    navigate("/");
  }

  return shop.email ? (
    <div className="flex gap-9">
      <ShopData shopForm={shopForm} />
      <TeamData
        members={shop.agronomists}
        addMember={() => setModalOpen(true)}
      />
      <Modal open={modalOpen} setOpen={setModalOpen}>
        <InviteUsersModal
          toggleModal={() => setModalOpen(!modalOpen)}
          inviteUsersSection="agronomists"
          onSuccess={() => dispatch(getAgronomists())}
        />
      </Modal>
    </div>
  ) : null;
};

export default Shop;
