import React from "react";
import { NavLink } from "react-router-dom";
import { Home } from "assets/icons";
import { Logo } from "components";
import { routes } from "routes/utils";
import { selectUser } from "features/user/userSlice";
import { useAppSelector } from "app/hooks";
import { UserPersona } from "types/models";

const MenuSidebar = () => {
  const user = useAppSelector(selectUser);
  return (
    <div className="fixed inset-y-0 flex w-72 flex-col">
      <div className="mb-7 ml-6 mr-9 mt-6 flex grow flex-col gap-y-5 overflow-y-auto rounded-2xl bg-green-50 pb-4 pt-8">
        <div className="flex items-center justify-between pl-6 pr-4">
          <Logo className="w-32" color="white" />
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${isActive ? "bg-opacity-50" : ""} flex h-11 w-11 rounded-full`
            }
          >
            <Home className="m-auto text-white" />
          </NavLink>
        </div>
        <div className="mt-12 flex flex-col">
          <span className="mb-6 pl-6 pr-4 text-sm font-semibold text-white opacity-30">
            ΠΕΛΑΤΟΛΟΓΙΟ
          </span>
          <NavLink
            className={({ isActive }) =>
              `${
                isActive ? "bg-opacity-100" : ""
              } cursor-pointer py-3 text-xl font-extrabold text-white`
            }
            to={routes.farm}
          >
            <span className="pl-6 pr-4">Αγροτεμάχια</span>
          </NavLink>
          {user.persona === UserPersona.AGRONOMIST && (
            <NavLink
              className={({ isActive }) =>
                `${
                  isActive ? "bg-opacity-100" : ""
                } cursor-pointer py-3 text-xl font-extrabold text-white`
              }
              to={routes.farmers}
            >
              <span className="pl-6 pr-4">Πελάτες</span>
            </NavLink>
          )}
          <NavLink
            className={({ isActive }) =>
              `${
                isActive ? "bg-opacity-100" : ""
              } cursor-pointer py-3 text-xl font-extrabold text-white`
            }
            to={routes.chat}
          >
            <span className="pl-6 pr-4">Μηνύματα</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default MenuSidebar;
