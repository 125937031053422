export const LOCAL_STORAGE_TOKENS = {
  access: "token",
  refresh: "refreshToken"
};

export const getLocalStorageAccessToken = () =>
  localStorage.getItem(LOCAL_STORAGE_TOKENS.access);

export const getLocalStorageRefreshToken = () =>
  localStorage.getItem(LOCAL_STORAGE_TOKENS.refresh);

export const setLocalStorageToken = (token: string) =>
  localStorage.setItem(LOCAL_STORAGE_TOKENS.access, `Bearer ${token}`);

export const setLocalStorageRefreshToken = (refreshToken: string) =>
  localStorage.setItem(LOCAL_STORAGE_TOKENS.refresh, refreshToken);

export const removeLocalStorageTokens = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKENS.access);
  localStorage.removeItem(LOCAL_STORAGE_TOKENS.refresh);
};
