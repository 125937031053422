import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { SnackBarType } from "types/models/SnackBar";

const initialState = {
  snackBar: {
    message: "",
    type: null as SnackBarType
  }
};

export const uiSlice = createSlice({
  initialState,
  name: "ui",
  reducers: {
    triggerSnackBar: (
      state,
      action: PayloadAction<{ message: string; type: SnackBarType }>
    ) => {
      state.snackBar.message = action.payload.message;
      state.snackBar.type = action.payload.type;
    }
  }
});

export const { triggerSnackBar } = uiSlice.actions;

export const selectSnackbar = (state: RootState) => state.ui.snackBar;

export default uiSlice.reducer;
