import React, { forwardRef } from "react";
import { InputProps } from "./types";

const Input = forwardRef<HTMLInputElement, InputProps>(function ForwardedInput(
  {
    type = "text",
    name,
    placeholder,
    value,
    className = "",
    onChange,
    errorMessage,
    required = false,
    maxLength,
    minLength,
    withIcon,
    handleIconClick,
    Icon,
    iconDisabled,
    disabled
  },
  ref
) {
  const hasError = !!errorMessage;
  return (
    <div className="relative w-full">
      <input
        ref={ref}
        type={type}
        name={name}
        placeholder={`${placeholder} ${required ? "*" : ""}`}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        aria-disabled={disabled}
        className={`line-clamp-1 w-full rounded-10 bg-grey-300 py-5 leading-5 placeholder-opacity-300 placeholder:pl-3 placeholder:font-semibold ${
          hasError ? "!border-2 !border-red-50" : "border-none"
        } ${disabled ? "text-gray-400" : "text-black"} ${className}`}
      />
      {hasError && (
        <span className="ml-4 mt-2 text-xs font-semibold text-red-50">
          {errorMessage}
        </span>
      )}
      {withIcon && (
        <div
          onClick={handleIconClick}
          className={`absolute inset-y-0 right-0 flex items-center rounded-10 bg-grey-300 px-3 ${
            iconDisabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          role="none"
        >
          {Icon}
        </div>
      )}
    </div>
  );
});

export default Input;
