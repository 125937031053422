import React, { Fragment, PropsWithChildren, lazy } from "react";

const TransitionRoot = lazy(() =>
  import("@headlessui/react").then(module => {
    return { default: module.Transition.Root };
  })
);

const TransitionChild = lazy(() =>
  import("@headlessui/react").then(module => {
    return { default: module.Transition.Child };
  })
);

const Dialog = lazy(() =>
  import("@headlessui/react").then(module => {
    return { default: module.Dialog };
  })
);
const DialogPanel = lazy(() =>
  import("@headlessui/react").then(module => {
    return { default: module.Dialog.Panel };
  })
);

type ModalProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const Modal = ({ open, setOpen, children }: PropsWithChildren<ModalProps>) => {
  return (
    <TransitionRoot show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  );
};

export default Modal;
