import React, { ChangeEvent, useRef } from "react";
import { UploadInputProps } from "./types";

const UploadInput = ({
  accept = "image/jpeg,image/png",
  multiple = false,
  imagePreview,
  className,
  onSelectOfFile
}: UploadInputProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    onSelectOfFile(e.target.files);
  };
  const handleUploadClick = () => {
    inputRef?.current.click();
  };

  return (
    <div
      className={`flex items-center gap-5 rounded-10 bg-grey-300 p-3 ${className}`}
    >
      <img
        src={imagePreview}
        alt="upload-input-logo"
        className="h-14 w-14 rounded-lg border border-solid border-opacity-100 object-cover"
      />
      <div role="none" onClick={handleUploadClick}>
        <span className="cursor-pointer font-semibold text-opacity-300 underline">
          Αλλαγή
        </span>
      </div>
      <input
        type="file"
        hidden
        multiple={multiple}
        ref={inputRef}
        accept={accept}
        onChange={onSelectFile}
      />
    </div>
  );
};

export default UploadInput;
