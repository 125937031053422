import React from "react";
import logoGreen from "assets/icons/logo-green.svg";
import logoWhite from "assets/icons/logo-white.svg";
import { LogoProps } from "./types";

const Logo = ({ className = "", color = "green" }: LogoProps) => {
  return (
    <img
      src={color === "green" ? logoGreen : logoWhite}
      alt="AgrosLogo"
      className={className}
    />
  );
};

export default Logo;
