import { HTMLProps } from "react";
import { ButtonVariant, ButtonVariantType } from "./types";

export const handleVariantStyles = (variant: ButtonVariantType) => {
  let styles: HTMLProps<HTMLElement>["className"] = "";

  switch (variant) {
    case ButtonVariant.SECONDARY:
      styles = "border border-solid border-opacity-200 bg-white text-black";
      break;
    case ButtonVariant.DASHED:
      styles = "border border-dashed bg-white text-black";
      break;
    default:
      styles = "bg-green-50 text-white";
      break;
  }

  return styles;
};
