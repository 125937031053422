import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { REGISTER_SCOPE } from "types/models/RegisterScope";
import { ScopeDTO } from "lib/api";
import { RegisterFormData } from "./types";
import { fetchUserScope, forgetPassword } from "./registerAPI";

const initialState: RegisterFormData = {
  firstName: "",
  lastName: "",
  companyName: "",
  companyEmail: "",
  address: "",
  phone: "",
  tin: "",
  email: "",
  mobilePhone: "",
  shopRegistrationToken: null,
  ownerRegistrationToken: null,
  phoneVerificationToken: null
};

type ValueOf<T> = T[keyof T];

export const getUserScope = createAsyncThunk(
  "user/getUserScope",
  async (data: { uuid: string }) => {
    try {
      const response = await fetchUserScope(data.uuid);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const forgetUserPassword = createAsyncThunk(
  "user/forgetPassword",
  async (data: { email: string }) => {
    try {
      const response = await forgetPassword(data.email);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    updateState: (
      state,
      action: PayloadAction<{
        key: keyof RegisterFormData;
        value: ValueOf<RegisterFormData>;
      }>
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      getUserScope.fulfilled,
      (state, action: PayloadAction<ScopeDTO>) => {
        state.phoneVerificationToken =
          action.payload.scope === REGISTER_SCOPE.RESET_PASSWORD ||
          action.payload.scope === REGISTER_SCOPE.SET_PASSWORD
            ? action.payload.token
            : state.phoneVerificationToken;
        state.shopRegistrationToken =
          action.payload.scope === REGISTER_SCOPE.REGISTER_AGRONOMIST ||
          action.payload.scope === REGISTER_SCOPE.REGISTER_FARMER
            ? action.payload.token
            : state.shopRegistrationToken;
      }
    );
  }
});

export const { updateState } = registerSlice.actions;

export const selectFields = (state: RootState) => state.register;

export default registerSlice.reducer;
