import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Farmer } from "types/models";
import { RootState } from "app/store";
import { UpdateFarmerRequestType } from "lib/api/requests";
import {
  fetchFarmers,
  inviteFarmersToShop,
  updateFarmer as updateFarmerApi
} from "./farmersApi";

export interface FarmersState {
  farmers: Farmer[];
  selectedFarmer: Farmer;
  invites: string[];
}

const initialState: FarmersState = {
  farmers: [],
  selectedFarmer: null,
  invites: null
};

export const getFarmers = createAsyncThunk("farmes/getFarmers", async () => {
  try {
    const response = await fetchFarmers();
    return response.data;
  } catch (error) {
    return error;
  }
});

export const inviteFarmers = createAsyncThunk(
  "farmers/inviteFarmers",
  async (data: { contacts: string[] }, { rejectWithValue }) => {
    try {
      const response = await inviteFarmersToShop(data.contacts);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateFarmer = createAsyncThunk(
  "farmers/updateFarmer",
  async (data: UpdateFarmerRequestType, { rejectWithValue }) => {
    try {
      const response = await updateFarmerApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const farmersSlice = createSlice({
  name: "farmers",
  initialState,
  reducers: {
    inviteAgronomists: (state, action: PayloadAction<{ emails: string[] }>) => {
      state.invites = action.payload.emails;
    },
    updateSelectedFarmer: (state, action) => {
      state.selectedFarmer = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      getFarmers.fulfilled,
      (state, action: PayloadAction<Farmer[]>) => {
        state.farmers = action.payload;
      }
    );
  }
});

export const { inviteAgronomists, updateSelectedFarmer } = farmersSlice.actions;

export const selectFarmers = (state: RootState) => state.farmers.farmers;
export const selectFarmer = (state: RootState) => state.farmers.selectedFarmer;

export default farmersSlice.reducer;
