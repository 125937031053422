import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { selectToken } from "features/user/userSlice";
import { getLocalStorageAccessToken } from "./storage";

const Authenticated = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  const stateToken = useAppSelector(selectToken);
  const storageToken = getLocalStorageAccessToken();
  const isSignedIn = !!(storageToken || stateToken);

  if (!isSignedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default Authenticated;
