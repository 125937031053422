import React, { Suspense, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getLocalStorageAccessToken,
  getLocalStorageRefreshToken
} from "lib/utils/storage";
import getTitleFromLocation from "lib/utils/location";
import {
  selectUser,
  updateToken,
  getUserInformation
} from "features/user/userSlice";
import MenuSidebar from "./MenuSidebar";
import Header from "./Header";
// import ChatSidebar from "./ChatSidebar";

const SignedInLayout = () => {
  const [pageTitle, setTitle] = useState<string>(null);
  const { pathname } = useLocation();
  const user = useAppSelector(selectUser);
  const storageRefreshToken = getLocalStorageRefreshToken();
  const userDetails = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const storageToken = getLocalStorageAccessToken();

  useEffect(() => {
    if (storageToken) {
      dispatch(
        updateToken({ access: storageToken, refresh: storageRefreshToken })
      );
      if (!userDetails?.id) {
        dispatch(getUserInformation());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageToken]);

  useEffect(() => {
    setTitle(getTitleFromLocation(pathname));
  }, [pathname]);

  return user ? (
    <Suspense fallback={<div>loading......</div>}>
      <div>
        <MenuSidebar />

        {/* Replace pr-16 with pr-36 when chat sidebar is ready to be shown */}
        <div className="pl-72 pr-16 pt-12">
          <Header pageTitle={pageTitle} />
          <main className="py-12">
            <Outlet />
          </main>
        </div>

        {/* Hidden chat sidebar until chat is functional */}
        {/* <ChatSidebar /> */}
      </div>
    </Suspense>
  ) : (
    <div>loading...</div>
  );
};

export default SignedInLayout;
