import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Home,
  Login,
  ErrorPage,
  Farm,
  Farmers,
  OneTimePassword,
  CreatePassword,
  ForgotPassword,
  Account,
  Shop,
  Chat
} from "features";
import {
  ShopDetails,
  AdminDetails,
  AgronomistDetails
} from "features/register/components";
import Layout from "components/layout";
import Authenticated from "lib/utils/authentication";
import NoMatch from "features/no-match";
import { useAppSelector } from "app/hooks";
import { getLocalStorageAccessToken } from "lib/utils/storage";
import { selectToken } from "features/user/userSlice";
import { routes, unprotectedRoutes } from "./utils";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routes.login,
        element: <Login />
      },
      {
        path: routes.registerShop,
        element: <ShopDetails />
      },
      {
        path: routes.registerAdmin,
        element: <AdminDetails />
      },
      {
        path: routes.registerAgronomist,
        element: <AgronomistDetails />
      },
      {
        path: routes.phoneVerification,
        element: <OneTimePassword />
      },
      {
        path: routes.passwordGeneration,
        element: <CreatePassword />
      },
      {
        path: routes.forgotPassword,
        element: <ForgotPassword />
      },
      {
        index: true,
        element: (
          <Authenticated>
            <Home />
          </Authenticated>
        )
      },
      {
        path: routes.farmers,
        element: (
          <Authenticated>
            <Farmers />
          </Authenticated>
        )
      },
      {
        path: routes.farm,
        element: (
          <Authenticated>
            <Farm />
          </Authenticated>
        )
      },
      {
        path: routes.chat,
        element: (
          <Authenticated>
            <Chat />
          </Authenticated>
        )
      },
      {
        path: routes.account,
        element: (
          <Authenticated>
            <Account />
          </Authenticated>
        )
      },
      {
        path: routes.shop,
        element: (
          <Authenticated>
            <Shop />
          </Authenticated>
        )
      },
      {
        path: "*",
        element: <NoMatch />
      }
    ]
  }
]);

const AppRouter = () => {
  const stateToken = useAppSelector(selectToken);
  const storageToken = getLocalStorageAccessToken();
  const isSignedIn = !!(storageToken || stateToken);
  if (
    isSignedIn &&
    unprotectedRoutes.includes(window.location.pathname.replace("/", ""))
  ) {
    window.location.replace("/");
    return null;
  }
  return <RouterProvider router={router} />;
};

export default AppRouter;
