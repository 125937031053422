import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectFields, updateState } from "features/register/registerSlice";
import { Button, Input, FormWrapper } from "components";
import { REGISTER_FORM_FIELDS } from "features/register/config";
import { RegisterFormData } from "features/register/types";
import { routes } from "routes/utils";
import { getInstance, endpoints, ShopRegistrationResponse } from "lib/api";
import { triggerSnackBar } from "components/uiSlice";
import { SnackBarConstType } from "types/models/SnackBar";
import RegisterLayout from "../RegisterLayout";

const ShopDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectFields);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<RegisterFormData>({
    defaultValues: fields,
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  });
  const formFields = watch();

  const onSubmitOfRegistration = () => {
    Object.keys(formFields).map((key: keyof RegisterFormData) =>
      dispatch(updateState({ key, value: formFields[key] }))
    );

    const payload = {
      address: formFields.address,
      name: formFields.companyName,
      email: formFields.companyEmail,
      phone: formFields.phone,
      tin: formFields.tin
    };

    getInstance
      .post(endpoints.validateShop, payload)
      .then((response: ShopRegistrationResponse) => {
        dispatch(
          updateState({
            key: "shopRegistrationToken",
            value: response.data.token
          })
        );
        navigate(`/${routes.registerAdmin}`);
      })
      .catch(error => {
        Object.values(error.response.data).map((errorMessages: string[]) =>
          errorMessages.map(message =>
            dispatch(
              triggerSnackBar({ message, type: SnackBarConstType.WARNING })
            )
          )
        );
      });
  };

  const renderSection = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmitOfRegistration)}
        className="flex flex-col gap-4"
      >
        <Input
          {...register("tin", { ...REGISTER_FORM_FIELDS.tin })}
          name="tin"
          placeholder="ΑΦΜ επιχείρησης"
          maxLength={9}
          required={REGISTER_FORM_FIELDS.tin.required}
          errorMessage={errors?.tin?.message}
        />
        <Input
          {...register("companyName", { ...REGISTER_FORM_FIELDS.companyName })}
          name="companyName"
          placeholder="Επωνυμία επιχείρησης"
          required={REGISTER_FORM_FIELDS.companyName.required}
        />
        <Input
          {...register("address", { ...REGISTER_FORM_FIELDS.address })}
          name="address"
          placeholder="Διεύθυνση"
          required={REGISTER_FORM_FIELDS.address.required}
        />
        <div className="flex w-full gap-6">
          <Input
            {...register("phone", { ...REGISTER_FORM_FIELDS.phone })}
            name="phone"
            placeholder="Τηλέφωνο"
            className="w-2/4"
            maxLength={10}
            required={REGISTER_FORM_FIELDS.phone.required}
            errorMessage={errors?.phone?.message}
          />
          <Input
            {...register("companyEmail", {
              ...REGISTER_FORM_FIELDS.companyEmail
            })}
            name="companyEmail"
            placeholder="Email"
            className="w-2/4"
            required={REGISTER_FORM_FIELDS.companyEmail.required}
            errorMessage={errors?.companyEmail?.message}
          />
        </div>
        <div className="mt-11 flex items-center justify-between gap-6">
          <div className="w-2/4">
            <button
              type="button"
              className="cursor-pointer text-left text-sm font-semibold opacity-60"
              onClick={() => navigate(`/${routes.login}`)}
            >
              Πίσω
            </button>
          </div>
          <Button label="Συνέχεια" type="submit" className="w-2/4" />
        </div>
      </form>
    );
  };

  return (
    <RegisterLayout>
      <FormWrapper
        title="Στοιχεία καταστήματος"
        step={1}
        form={renderSection()}
      />
    </RegisterLayout>
  );
};

export default ShopDetails;
