import React from "react";
import { Logo } from "components";

const RegisterLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex justify-end pr-9 pt-9">
        <Logo className="w-20" />
      </div>
      <div className="flex flex-1 items-center justify-center">{children}</div>
      <div className="flex justify-end gap-7 pb-9 pr-9 pt-9">
        <span className="font-semibold text-opacity-300 underline">
          Όροι και Προϋποθέσεις
        </span>
        <span className="font-semibold text-opacity-300 underline">
          Επικοινωνία
        </span>
      </div>
    </div>
  );
};

export default RegisterLayout;
