import React, { useState } from "react";
import { Button, Input } from "components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "app/hooks";
import { routes } from "routes/utils";
import { RegisterLayout } from "features/register/components";
import { forgetUserPassword } from "features/register/registerSlice";
import ForgotPasswordSuccessMessage from "./components/ForgotPasswordSuccessMessage";
import { FORGOT_PASSWORD_FORM_FIELDS } from "./config";

type FormData = {
  email: string;
};

const ForgotPassword = () => {
  const [shouldDisplaySuccessMessage, setSuccessMessage] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  });

  const submitForm = (data: FormData) => {
    dispatch(forgetUserPassword({ email: data.email })).then(() =>
      setSuccessMessage(true)
    );
  };

  return (
    <RegisterLayout>
      {shouldDisplaySuccessMessage ? (
        <ForgotPasswordSuccessMessage />
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <div className="flex w-full max-w-xl flex-col rounded-10 border border-solid border-opacity-100 bg-white p-9 shadow-100">
            <div className="flex flex-col">
              <span className="mb-3 text-3xl font-extrabold">
                Επαναφορά κωδικού
              </span>
              <span className="text-sm text-opacity-600">
                Εισάγετε το email ή το επιβεβαιωμένο κινητό σας τηλέφωνο και
                στην περίπτωση που υπάρχει αντίστοιχος λογαριασμός θα παραλάβετε
                έναν σύνδεσμο επαναφοράς κωδικού.
              </span>
            </div>
            <form className="mt-8" onSubmit={handleSubmit(submitForm)}>
              <div className="mb-52 flex flex-col gap-4">
                <Input
                  {...register("email", {
                    ...FORGOT_PASSWORD_FORM_FIELDS.email
                  })}
                  placeholder="Email ή κινητό τηλέφωνο"
                  required
                  errorMessage={errors?.email?.message}
                  name="email"
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex-1">
                  <button
                    type="button"
                    className="cursor-pointer text-left text-sm font-semibold opacity-60"
                    onClick={() => navigate(`/${routes.login}`)}
                  >
                    Ακύρωση
                  </button>
                </div>
                <Button label="Υποβολή" type="submit" className="flex-1" />
              </div>
            </form>
          </div>
        </div>
      )}
    </RegisterLayout>
  );
};

export default ForgotPassword;
