import React from "react";

const NameInitials = ({
  firstName,
  lastName,
  className
}: {
  firstName: string;
  lastName: string;
  className?: string;
}) => {
  return (
    <div
      className={`flex h-8 w-8 items-center justify-center rounded-full bg-grey-500 px-1 py-2 text-sm font-semibold text-white ${className}`}
    >
      {firstName.charAt(0).toUpperCase()}
      {lastName.charAt(0).toUpperCase()}
    </div>
  );
};

export default NameInitials;
