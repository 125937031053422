import React from "react";
import { useForm } from "react-hook-form";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectUser, updateUser } from "features/user/userSlice";
import { Button, FormWrapper, Input } from "components";
import { UserDetailsResponse, endpoints, getInstance } from "lib/api";
import { UpdateUserRequestType } from "lib/api/requests";
import { convertPropsToCamelCase } from "lib/utils/dev";
import { ACCOUNT_FIELDS } from "./config";

const Account = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UpdateUserRequestType>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobile: user.mobile
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  });

  const onSubmit = async (data: UpdateUserRequestType) => {
    const { firstName, lastName, email, mobile } = data;
    const response: UserDetailsResponse = await getInstance.patch(
      endpoints.userInformation,
      {
        email,
        mobile,
        first_name: firstName,
        last_name: lastName
      }
    );
    if (response.data) {
      dispatch(updateUser(convertPropsToCamelCase(response.data)));
    }
  };

  const renderForm = () => {
    return (
      <div>
        <form
          className="mt-5 flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            {...register("firstName", { ...ACCOUNT_FIELDS.firstName })}
            name="firstName"
            placeholder="Όνομα"
            required
          />
          <Input
            {...register("lastName", { ...ACCOUNT_FIELDS.lastName })}
            name="lastName"
            placeholder="Επώνυμο"
            required
          />
          <Input
            {...register("mobile", { ...ACCOUNT_FIELDS.mobile })}
            name="mobile"
            placeholder="Τηλέφωνο"
            required
            maxLength={10}
            errorMessage={errors?.mobile?.message}
          />
          <Input
            {...register("email", { ...ACCOUNT_FIELDS.email })}
            name="email"
            placeholder="Email"
            errorMessage={errors?.email?.message}
            required
          />
          <div className="flex justify-end">
            <Button type="submit" label="Αποθήκευση" />
          </div>
        </form>
      </div>
    );
  };

  return user ? (
    <FormWrapper
      title="Ο λογαριασμός μου"
      withStep={false}
      form={renderForm()}
    />
  ) : (
    <div>...loading</div>
  );
};

export default Account;
