import { ObjectValues } from "types/models/Common";

export const ButtonVariant = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DASHED: "dashed"
} as const;

export type ButtonVariantType = ObjectValues<typeof ButtonVariant>;

export type ButtonProps = {
  label: string;
  variant?: ButtonVariantType;
  onClick?: () => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
  className?: string;
  withIcon?: boolean;
  Icon?: React.ReactNode;
};
