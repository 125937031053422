import React from "react";
import { FormWrapperProps } from "./types";

const FormWrapper = ({
  title,
  form,
  step,
  withStep = true,
  className = ""
}: FormWrapperProps) => {
  return (
    <div
      className={`w-full max-w-xl rounded-10 border border-solid border-opacity-100 bg-white px-9 pb-9 pt-8 shadow-100 ${className}`}
    >
      <div className="mb-8 flex flex-col">
        {withStep && (
          <span className="mb-2 text-xs opacity-60">Βήμα {step} από 3</span>
        )}
        <span className="text-3xl font-extrabold">{title}</span>
      </div>
      <div className="h-full">{form}</div>
    </div>
  );
};

export default FormWrapper;
