import {
  getInstance,
  endpoints,
  LoginResponse,
  UserDetailsResponse
} from "lib/api";
import { convertPropsToCamelCase } from "lib/utils/dev";

const fetchUserInformation = async () => {
  const userResponse: UserDetailsResponse = await getInstance.get(
    endpoints.userInformation
  );

  return convertPropsToCamelCase(userResponse.data);
};

const logInUser = async (username: string, password: string) => {
  const loginResponse: LoginResponse = await getInstance.post(endpoints.login, {
    username,
    password
  });

  return loginResponse.data;
};

const logOutUser = async (refreshToken: string) => {
  const response = await getInstance.post(endpoints.logout, {
    refresh: refreshToken
  });

  return response;
};

export { logInUser, logOutUser, fetchUserInformation };
