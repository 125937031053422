import { ObjectValues } from "./Common";

export const UserPersona = {
  FARMER: "farmer",
  AGRONOMIST: "agronomist"
} as const;

export type UserPersonaType = ObjectValues<typeof UserPersona>;

export type User = {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  fullName: string;
  isAdmin: boolean;
  id: string;
  mobile: string;
  shopId: string;
  persona?: UserPersonaType;
};
