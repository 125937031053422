import React from "react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import { SnackBarConstType, SnackBarType } from "types/models/SnackBar";

export const handleSnackBarIcon = (type: SnackBarType) => {
  switch (type) {
    case SnackBarConstType.ERROR:
      return <XCircleIcon className="h-6 w-6 text-red-50" aria-hidden="true" />;
    case SnackBarConstType.WARNING:
      return (
        <ExclamationCircleIcon
          className="h-6 w-6 text-orange-400"
          aria-hidden="true"
        />
      );
    case SnackBarConstType.SUCCESS:
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      );
    default:
      return <span />;
  }
};
