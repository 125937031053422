import { getInstance, endpoints, ScopeResponse } from "lib/api";

const fetchUserScope = async (uuid: string) => {
  const response: ScopeResponse = await getInstance.get(
    `${endpoints.magicLink}/${uuid}/`
  );

  return response.data;
};

const forgetPassword = async (email: string) => {
  const response = await getInstance.post(endpoints.forgotPassword, { email });

  return response.data;
};

export { fetchUserScope, forgetPassword };
