import {
  emailRegxPattern,
  validatePhoneNumber,
  validateTaxNumber
} from "lib/utils/validation";

export const SHOP_FIELDS = {
  email: {
    required: true,
    pattern: {
      value: emailRegxPattern,
      message: "Λάθος email"
    }
  },
  name: {
    required: true
  },
  address: {
    required: true
  },
  phone: {
    required: true,
    validate: (phone: string) =>
      validatePhoneNumber(phone) || "Εισάγετε έγκυρο αριθμό τηλεφώνου"
  },
  tin: {
    required: true,
    validate: (value: string) =>
      validateTaxNumber(value) || "Εισάγετε έγκυρο ΑΦΜ"
  }
};
