import axios from "axios";
import {
  getLocalStorageAccessToken,
  getLocalStorageRefreshToken,
  removeLocalStorageTokens,
  setLocalStorageRefreshToken,
  setLocalStorageToken
} from "lib/utils/storage";
import { routes } from "routes/utils";
import { LoginResponse, endpoints } from ".";

export const getApiVersion = (version?: "v2" | "v3") => {
  if (version) return version;
  return "v1";
};

export const getInstance = axios.create({
  // baseURL: "http://127.0.0.1:8001/api/v1/"
  baseURL: "https://backend.agros.ai/api/v1/"
});

getInstance.interceptors.request.use(
  async config => {
    const token = getLocalStorageAccessToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let count = 0;

getInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalConfig = error.config;
    if (error.response) {
      if (
        error.response.status === 401 &&
        count < 10 &&
        window.location.pathname.replace("/", "") !== routes.login
      ) {
        count += 1;
        try {
          const refreshTokenResponse: LoginResponse = await getInstance.post(
            endpoints.refreshToken,
            {
              refresh: getLocalStorageRefreshToken()
            }
          );
          const { access, refresh } = refreshTokenResponse.data;
          removeLocalStorageTokens();
          setLocalStorageToken(access);
          setLocalStorageRefreshToken(refresh);
          return getInstance(originalConfig);
        } catch (err) {
          localStorage.clear();
          window.location.assign(`/${routes.login}`);
          return err;
        }
      }
    }
    return Promise.reject(error);
  }
);
