export { ReactComponent as Chat } from "./chat.svg";
export { ReactComponent as Check } from "./check.svg";
export { ReactComponent as ChevronLeft } from "./chevron-left.svg";
export { ReactComponent as EmailCheck } from "./email-check.svg";
export { ReactComponent as Grid } from "./grid.svg";
export { ReactComponent as Home } from "./home.svg";
export { ReactComponent as Kebap } from "./kebap.svg";
export { ReactComponent as Lock } from "./lock.svg";
export { ReactComponent as Mail } from "./mail.svg";
export { ReactComponent as Map } from "./map.svg";
export { ReactComponent as Phone } from "./phone.svg";
export { ReactComponent as Plus } from "./plus.svg";
export { ReactComponent as Rocket } from "./rocket.svg";
export { ReactComponent as SearchIcon } from "./search.svg";
export { ReactComponent as UserIcon } from "./user.svg";
