import transform from "lodash/transform";
import camelCase from "lodash/camelCase";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

export const convertPropsToCamelCase = <
  Result extends Record<string, unknown>,
  ObjectToConvert extends Record<string, unknown> = Record<string, unknown>
>(
  obj: ObjectToConvert
): Result => {
  return transform(obj, (acc, value, key, target) => {
    const camelKey = (isArray(target) ? key : camelCase(key)) as keyof Result;

    acc[camelKey] = (
      isObject(value)
        ? convertPropsToCamelCase(value as Record<string, unknown>)
        : value
    ) as Result[keyof Result];

    return acc;
  });
};
