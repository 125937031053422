import { emailRegxPattern, validatePhoneNumber } from "lib/utils/validation";

export const ACCOUNT_FIELDS = {
  email: {
    required: true,
    pattern: {
      value: emailRegxPattern,
      message: "Λάθος email"
    }
  },
  firstName: {
    required: true
  },
  lastName: {
    required: true
  },
  mobile: {
    required: true,
    validate: (phone: string) =>
      validatePhoneNumber(phone) || "Εισάγετε έγκυρο αριθμό τηλεφώνου"
  }
};
