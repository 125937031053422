import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "app/hooks";
import { signInUser } from "features/user/userSlice";
import { getUserScope, updateState } from "features/register/registerSlice";
import { triggerSnackBar } from "components/uiSlice";
import { Logo, Input, Button } from "components";
import { routes } from "routes/utils";
import { REGISTER_SCOPE } from "types/models/RegisterScope";
import { ButtonVariant } from "components/button/types";
import { SnackBarConstType } from "types/models/SnackBar";
import { isEmpty } from "lodash";
import { LOGIN_FORM_FIELDS } from "./config";

type FormData = {
  email: string;
  password: string;
};

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const code = params.get("code");
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  });

  useEffect(() => {
    if (code) {
      const getScope = async () => {
        const response = await dispatch(getUserScope({ uuid: code }));
        return response;
      };

      getScope().then(({ payload: { scope, data } }) => {
        if (
          scope === REGISTER_SCOPE.SET_PASSWORD ||
          scope === REGISTER_SCOPE.RESET_PASSWORD
        ) {
          navigate(`/${routes.passwordGeneration}`);
        } else if (
          scope === REGISTER_SCOPE.REGISTER_AGRONOMIST ||
          scope === REGISTER_SCOPE.REGISTER_FARMER
        ) {
          if (!isEmpty(data)) {
            dispatch(
              updateState({ key: "firstName", value: data?.first_name })
            );
            dispatch(updateState({ key: "lastName", value: data?.last_name }));
            dispatch(updateState({ key: "email", value: data?.email }));
            dispatch(updateState({ key: "mobilePhone", value: data?.mobile }));
          }
          navigate(`/${routes.registerAgronomist}`, {
            state: {
              userType: REGISTER_SCOPE.REGISTER_AGRONOMIST
                ? "agronomist"
                : "farmer"
            }
          });
        }
      });
    }
  }, [code, dispatch, navigate]);

  const submitForm = (data: FormData) => {
    dispatch(
      signInUser({
        email: data.email,
        password: data.password
      })
    )
      .unwrap()
      .then(() => navigate("/"))
      .catch(() => {
        dispatch(
          triggerSnackBar({
            message: "Δεν βρέθηκε ο χρήστης",
            type: SnackBarConstType.WARNING
          })
        );
      });
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex w-full max-w-xl flex-col rounded-10 border border-solid border-opacity-100 bg-white px-32 pb-14 pt-12 shadow-100">
        <div className="flex justify-center">
          <Logo className="w-36" />
        </div>
        <form className="mt-16" onSubmit={handleSubmit(submitForm)}>
          <div className="mb-12 flex flex-col gap-4">
            <Input
              {...register("email", { ...LOGIN_FORM_FIELDS.email })}
              placeholder="Email"
              name="email"
              errorMessage={errors?.email?.message}
            />
            <Input
              {...register("password", { ...LOGIN_FORM_FIELDS.password })}
              placeholder="Password"
              type="password"
              name="password"
            />
          </div>
          <div className="flex flex-col gap-4">
            <Button label="Είσοδος" type="submit" />
            <Button
              label="Εγγραφή"
              variant={ButtonVariant.SECONDARY}
              onClick={() => navigate(`/${routes.registerShop}`)}
            />
          </div>
          <div
            className="mt-4 cursor-pointer text-center"
            onClick={() => {
              navigate(`/${routes.forgotPassword}`);
            }}
            role="none"
          >
            <span>Ξέχασα τον κωδικό μου</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
